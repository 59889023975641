import { List, ListItem, Typography, useTheme } from "@mui/material";
import { BONDI_BLUE, MAIN_FONT } from "../../../../constants.js/index.js";
import { map } from "lodash";
import { useBreakpoints } from "react-match-breakpoints";
import { Text } from "react-internationalization";

export const ClientsServices = () => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();

  const services = [
    {
      icon: "images/icons/massage.svg",
      title: "home.wellBeing.title",
      description: [
        "home.wellBeing.description1",
        "home.wellBeing.description2",
        "home.wellBeing.description3",
      ],
    },
    {
      icon: "images/icons/cirurgia.png",
      title: "home.elderly.title",
      description: ["home.elderly.description1", "home.elderly.description2"],
    },
    {
      icon: "images/icons/mom.svg",
      title: "home.woman.title",
      description: [
        "home.woman.description1",
        "home.woman.description2",
        "home.woman.description3",
        "home.woman.description4",
      ],
    },
  ];

  return (
    <div
      style={{
        zIndex: 100,
        background: BONDI_BLUE,
        display: "flex",
        borderRadius: "60px 60px 0px 0px",
        justifyContent: tabletDown ? "flex-start" : "center",
        paddingTop: theme.spacing(8),
        paddingLeft: theme.spacing(tabletDown ? 3 : 10),
        paddingRight: theme.spacing(tabletDown ? 3 : 10),
        paddingBottom: theme.spacing(tabletDown ? 3 : 4),
        gap: theme.spacing(tabletDown ? 1 : 5),
        alignItems: "flex-start ",
        flexWrap: "wrap",
      }}
    >
      {map(services, ({ icon, title, description }, index) => {
        return (
          <div
            key={`${title}-${index}`}
            style={{
              display: "flex",
              gap: theme.spacing(5),
              minHeight: 150,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                borderRadius: 15,
                width: 60,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(2),
                background: "white",
              }}
            >
              <img
                style={{
                  width: 60,
                  height: 60,
                }}
                src={icon}
                alt="Service"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  color: "white",
                  marginLeft: -17,
                  fontFamily: MAIN_FONT,
                }}
              >
                <Text id={title}></Text>
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {map(description, (d, index) => {
                  return (
                    <ListItem
                      key={"description-" + index}
                      sx={{
                        color: "white",
                        padding: 0,
                        textAlign: "left",
                        listStyleType: "disc",
                        display: "list-item",
                        fontFamily: MAIN_FONT,
                        fontSize: 14,
                      }}
                    >
                      <Text id={d}></Text>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        );
      })}
    </div>
  );
};

import React from "react";
import { map, size } from "lodash";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper React components
// Import Swiper styles
// Styles must use direct files imports

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

export default function MySwiper({ contents, SlideComponent, ...props }) {
  return (
    <Swiper
      modules={[Autoplay, Navigation]}
      spaceBetween={10}
      slidesPerView={2.5}
      pagination={{ clickable: true, autoplay: true }}
      scrollbar={{ draggable: true, autoplay: true }}
      loading="lazy"
      {...props}
    >
      {map(contents, (slide, index) => {
        const isFirst = index === 0;
        const isLast = size(contents) - 1 === index;
        return (
          <SwiperSlide key={index}>
            <SlideComponent {...{ isFirst, isLast, ...slide }} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

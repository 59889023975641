import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ClickableLogo = ({ boxStyles, imgStyles, logo = "logo" }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        ...boxStyles,
      }}
    >
      <img
        src={`${window.location.origin}/images/main_logo.png`}
        onClick={() => navigate("/")}
        width="250px"
        style={imgStyles}
      />
    </Box>
  );
};

import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  CLOUD_BURST,
  DARK_BLUE_GRAY,
  SERVICES,
} from "../../constants.js";
import { map } from "lodash";
import { Text } from "react-internationalization";
import { Link, useNavigate } from "react-router-dom";
import { useBreakpoints } from "react-match-breakpoints";
import VButton from "../V/VButton/index.js";

export const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { tabletDown } = useBreakpoints();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 80,
        marginTop: tabletDown ? 20 : 100,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: tabletDown ? "center" : "flex-end",
          gap: tabletDown ? theme.spacing(2) : 100,
          flexDirection: tabletDown ? "column" : "row",
        }}
      >
        <div
          id="firstcol"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 200,
            gap: 8,
          }}
        >
          <Box
            sx={{
              width: "fit-content",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <img
              onClick={() => {
                navigate("/");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              width="120px"
              src={`${window.location.origin}/images/logo_circulo.webp`}
              alt="Home page"
            />
          </Box>
          <Typography variant="caption" color={DARK_BLUE_GRAY}>
            VenturaCuida
          </Typography>
          <Typography variant="caption" color={DARK_BLUE_GRAY}>
            Rua do outeiro n7 Vila verde de ficalho 7830-654
          </Typography>
          <Typography variant="caption" color={DARK_BLUE_GRAY}>
            +351 928 095 512
          </Typography>
          <Typography variant="caption" color={DARK_BLUE_GRAY}>
            venturacareportugal@gmail.com
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <a href="/pdf/certidao-ers.pdf" target="blank">
              <img
                loading="lazy"
                src="/images/ers.webp"
                alt="VenturaCuida"
                width={70}
              />
            </a>
            <a href="https://www.livroreclamacoes.pt" target="blank">
              <div
                style={{
                  backgroundColor: "white",
                  padding: 20,
                  borderRadius: 50,
                }}
              >
                <img
                  loading="lazy"
                  src="/images/reclamacao/reclamacao.png"
                  alt="VenturaCuida"
                  width={100}
                />
              </div>
            </a>
          </div>
        </div>
        <div
          style={{
            padding: theme.spacing(2),
            display: "flex",
            gap: tabletDown ? theme.spacing(2) : 100,
          }}
        >
          <div
            id="secondcol"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 200,
              gap: 10,
            }}
          >
            <Typography
              style={{ fontSize: 20, marginBottom: 10, fontWeight: 700 }}
              color={CLOUD_BURST}
            >
              Useful Links
            </Typography>
            {map([], ({ pageName, href }, index) => (
              <Link
                href={href}
                component="button"
                underline="none"
                key={"links-" + index}
                style={{
                  fontSize: tabletDown ? 13 : 16,
                  fontWeight: 500,
                  color: DARK_BLUE_GRAY,
                }}
                to={href}
                onClick={() => {
                  navigate(href);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <Text id={pageName} />
              </Link>
            ))}
          </div>
          <div
            id="third"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 200,
              gap: 10,
            }}
          >
            <Typography
              style={{ fontSize: 20, marginBottom: 10, fontWeight: 700 }}
              color={CLOUD_BURST}
            >
              Services
            </Typography>
            {map(SERVICES.slice(0, 4), ({ name, id }, index) => (
              <Link
                key={"services-" + index}
                component="button"
                underline="none"
                style={{
                  fontSize: tabletDown ? 13 : 16,
                  fontWeight: 500,
                  color: DARK_BLUE_GRAY,
                }}
                to={{
                  pathname: "/services",
                  hash: id,
                }}
              >
                <Text id={name} />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          flexDirection: tabletDown ? "column" : "row",
        }}
      >
        <Typography
          color={DARK_BLUE_GRAY}
          style={{
            fontSize: 16,
            fontFamily: "Poppins",
            letterSpacing: -0.16,
          }}
        >
          VenturaCuida {new Date().getFullYear()}
        </Typography>
        {!tabletDown && (
          <Typography
            color={DARK_BLUE_GRAY}
            style={{ padding: theme.spacing(1) }}
          >
            {" "}
            -{" "}
          </Typography>
        )}
        <span
          style={{
            fontSize: 14,
            color: DARK_BLUE_GRAY,
            display: "flex",
            alignItems: "baseline",
          }}
        >
          Website desenvolvido por{" "}
          <VButton
            onClick={() => {
              window.open("https://www.camaraspark.com");
            }}
            showDot={null}
            style={{ padding: theme.spacing(1), marginTop: 3.5 }}
            variant="text"
          >
            David Câmara
          </VButton>
        </span>
      </div>

      <div style={{ height: 80, width: '100%', gap: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <VButton
          onClick={() => {
            navigate("/policy/privacy");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          showDot={null}
          style={{ padding: theme.spacing(1), marginTop: 3.5 }}
          variant="text"
        >
          <Text id="policy.privacy.id" />
        </VButton>
        <VButton
          onClick={() => {
            navigate("/policy/terms");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          showDot={null}
          style={{ padding: theme.spacing(1), marginTop: 3.5 }}
          variant="text"
        >
          <Text id="policy.terms.id" />
        </VButton>
        <VButton
          onClick={() => {
            navigate("/policy/protection");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          showDot={null}
          style={{ padding: theme.spacing(1), marginTop: 3.5 }}
          variant="text"
        >
          <Text id="policy.protection.id" />
        </VButton>
      </div>
    </div>
  );
};

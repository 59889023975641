import { AnimatePresence, motion } from "framer-motion";
import { Tabs } from "../../Tabs";
import { Language } from "../Language";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VButton from "../../V/VButton";
import { useRef } from "react";

export const Desktop = ({ scrollY }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const headerRef = useRef();

  return (
    <AnimatePresence>
      {scrollY < 20 && (
        <motion.div
          ref={headerRef}
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -100, opacity: 0 }}
          transition={{ type: "spring", stiffness: 100, duration: 0.4 }}
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              marginRight: theme.spacing(5),
            }}
          >
            <img
              src={`${window.location.origin}/images/main_logo.png`}
              onClick={() => navigate("/")}
              width="200px"
              alt="Home page"
            />
          </Box>
          <div
            style={{ display: "flex", alignItems: "center" }}
          >
            <Language />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

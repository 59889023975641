import { AnimatePresence, motion, useCycle } from "framer-motion";
import { Box, ClickAwayListener, useTheme } from "@mui/material";
import { AQUA_SQUEEZE, BONDI_BLUE, CLOUD_BURST, SPACE_CADET } from "../../../constants.js";
import { useEffect, useRef, useState } from "react";
import { times } from "lodash";
import { Menu } from "./Menu/index.js";

const Dot = ({ bgColor, index }) => {
  return (
    <div
      key={"dot-" + index}
      style={{
        height: "5px",
        width: "5px",
        backgroundColor: bgColor,
        borderRadius: "50%",
        display: "inline-block",
      }}
    />
  );
};

export const Mobile = ({ scrollY }) => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false, true);
  const toggleOpen = () => setOpen(!isOpen);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      backgroundColor: SPACE_CADET,
      transition: {
        type: "linear",
        restDelta: 0,
        duration: 1,
        backgroundColor: {
          type: "keyframes",
          easings: ["circIn", "circOut"],
          duration: 0.2,
        },
      },
    }),
    closed: {
      clipPath: "circle(30px at 40px 40px)",
      backgroundColor: BONDI_BLUE,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <AnimatePresence>
      <ClickAwayListener
        mouseEvent="onMouseUp"
        touchEvent="onTouchEnd"
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div style={{ position: "fixed", zIndex: 1000, top: 0 }}>
          <motion.nav
            style={{ border: "none", height: 200 }}
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={'auto'}
            ref={containerRef}
          >
            <motion.div className="background" variants={sidebar}>
              <Box
                onClick={() => {
                  toggleOpen();
                }}
                style={{
                  position: "absolute",
                  top: 31,
                  zIndex: 100000,
                  left: 31,
                  display: "flex",
                  flexWrap: "wrap",
                  width: 25,
                  gap: theme.spacing(1),
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {times(4, (index) => {
                  return (
                    <div
                      key={"dot-" + index}
                      style={{
                        height: "5px",
                        width: "5px",
                        backgroundColor: isOpen ? "white" : CLOUD_BURST,
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                  );
                })}
              </Box>
              <Menu {...{ toggleOpen }}></Menu>
            </motion.div>
          </motion.nav>
        </div>
      </ClickAwayListener>
    </AnimatePresence>
  );
};

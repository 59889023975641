import { useTheme } from "@mui/material";
import { map } from "lodash";
import { Text } from "react-internationalization";
import { Link, useNavigate } from "react-router-dom";
import { Language } from "../../Language/index.js";

export function Menu({ toggleOpen }) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: 'center',
        gap: theme.spacing(3),
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(4)
      }}
    >
      {map([], ({ pageName, href, id }, index) => {
        return (
          <Link
            href={href}
            component="button"
            underline="none"
            key={"links-" + index}
            style={{ fontSize: 16, fontWeight: 700, color: "white" }}
            to={href}
            onClick={() => {
              navigate(href);
              toggleOpen();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <Text id={pageName} />
          </Link>
        );
      })}
      <Language bgColor="#FFFFFF" />
    </div>
  );
}

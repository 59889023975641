import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { BRIGHT_GRAY, CLOUD_BURST, REVIEWS, WORK_SCHEDULE, blogs } from "../../../constants.js/index.js";
import { ClientsServices } from "./ClientsServices/index.js";
import { Content } from "./ProblemsWeSolve/index.js";
import { groupBy, map, orderBy, uniq } from "lodash";
import { BlogSection } from "./BlogSection/index.js";
import { motion } from "framer-motion";
import { useBreakpoints } from "react-match-breakpoints";
import { ClickableLogo } from "../../../components/ClickableLogo/index.js";
import { Text, translate } from "react-internationalization";
import MySwiper from "../../../components/MySwiper/index.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import opacity from 'hex-color-opacity';

export const Home = () => {
  const theme = useTheme();
  const { tabletDown, mobile } = useBreakpoints();

  const workPlacesByClinic = groupBy(WORK_SCHEDULE, 'clinic');

  console.log(uniq(Object.keys(workPlacesByClinic)));

  return (
    <div style={{ position: 'relative', display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: tabletDown ? "column" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: tabletDown ? "center" : "flex-start",
            marginTop: tabletDown ? 10 : 100,
            marginBottom: tabletDown ? 0 : 50,
            gap: theme.spacing(tabletDown ? 2 : 4),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}
        >
          {tabletDown && <ClickableLogo imgStyles={{ width: 150 }} />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: tabletDown ? '100%' : '650px', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Typography
                id="top"
                component={"h1"}
                variant="h1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: tabletDown ? "30px" : "74px",
                  position: "relative",
                  marginTop: tabletDown ? 50 : 100,
                }}
              >
                <span style={{ color: CLOUD_BURST }}>
                  <Text id={"physiotherapist"}></Text>
                </span>
              </Typography>
            </div>

            <Typography
              component={"h3"}
              style={{
                textAlign: "left",
                fontSizeAdjust: '30px',
                color: CLOUD_BURST,
              }}
            >
              <Text id={"takeCareOfYourSelf"} />
            </Typography>
          </div>
        </div>
        <div style={{ position: "relative", alignSelf: "end" }}>
          <motion.img
            fetchpriority="high"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            src="images/ana.webp"
            style={{
              height: "auto",
              width: tabletDown ? "300px" : "400px",
              ...(tabletDown && { margin: "auto", paddingLeft: 16 }),
              alignSelf: tabletDown ? "center" : "flex-end",
            }}
            alt="Ana"
          ></motion.img>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -20,
          padding: theme.spacing(1),
        }}
      >
        <ClientsServices />
        <div style={{ height: tabletDown ? 50 : 100 }}></div>
        <Content title={'Marque a sua consulta'}></Content>
        <Box display={'flex'} flexWrap={'wrap'} flexDirection={'row'} gap={2} justifyContent={'center'}>
          {(Object.keys(workPlacesByClinic)).map((clinicName) => {
            const clinic = workPlacesByClinic[clinicName];

            const horario = orderBy(clinic, 'dia');

            console.log({ horario });

            return <div style={{ maxWidth: 300, borderRadius: 10, flexDirection: 'column', display: 'flex', gap: 10, padding: 20, background: opacity(BRIGHT_GRAY, 0.3) }}>
              <Typography style={{ fontWeight: 700, }}>
                {clinicName}
              </Typography>
              <Typography style={{ opacity: 0.8 }}>
                {clinic[0].morada}
              </Typography>
              <Typography style={{ opacity: 0.8 }}>
                {clinic[0].phone}
              </Typography>
              <div>
                <Typography>Horários</Typography>
                {horario.map((h) => <Typography>
                  {h.dia} feira {h.horario.inicio} ás {h.horario.fim}
                </Typography>)}
              </div>
            </div>
          })}
        </Box>
        <div style={{ height: tabletDown ? 50 : 100 }}></div>
        <div style={{ width: tabletDown ? '100%' : '50%', fontStyle: 'italic' }}>
          <Content title={'O que dizem os seres lindos'}></Content>
          <MySwiper
            slidesPerView={1}
            contents={REVIEWS}
            navigation={{
              enabled: true,
              ...(!mobile && { nextEl: "#next", prevEl: "#prev" }),
            }}
            SlideComponent={({
              isFirst,
              isLast,
              ...props
            }) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Typography>
                  <Text id={props.text} />
                </Typography>
                <div>
                  <Typography style={{ opacity: 0.5 }}>{props.name}</Typography>
                </div>
              </div>
            )}
          >
          </MySwiper>
          {!mobile && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: "flex", height: 50, gap: theme.spacing(2) }}>
                <Button variant="contained" style={{ color: 'white' }} id="prev">
                  <ArrowBackIcon />
                </Button>
                <Button variant="contained" style={{ color: 'white' }} id="next">
                  <ArrowForwardIcon />
                </Button>
              </div>
            </div>
          )}
        </div>
        <div style={{ height: tabletDown ? 50 : 100 }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Content
            title={"Blog"}
            description={translate("home.blogExplore")}
          />
          <div
            style={{
              display: "flex",
              gap: theme.spacing(5),
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {map(blogs, ({ img, name, id }, index) => {
              return (
                <div key={index}>
                  <BlogSection {...{ img, name, id }} />
                </div>
              );
            })}
          </div>
          <div style={{ height: tabletDown ? 50 : 100 }}></div>
        </div>
      </div >
      <div style={{ height: tabletDown ? 50 : 100 }}></div>
    </div >
  );
};
